/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Sep 30 2021
 * Category: Pages
 * Title: Footer
 *
 */

import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap'; 

// styles
import './footer.css';

const Footer = (props) => {
  return (
    <footer className="footer">
      {window.location.pathname == '/careers' ?
        <></>
        :
        <Container>
          <Row className="row-justify-center">
            <Col lg="9">
              <div className="main-heading">
                <h2 className="main-title">8 years of building digital products and we are just getting started!</h2>
                <Button color="primary" onClick={() => window.location.href = '/contact' } size="lg">Start a Project</Button>
              </div>
            </Col>
          </Row>
        </Container>
      }
      <div className="footer-col-wrap">
        <Container>
          <Row>
            {/* <Col xs="12" md="6" lg="3">
              <div className="address-box">
                <div className="title-wrap">
                  <img src='images/us.png' alt="" className="flag-icon" />
                  <h3 className="title">UNITED STATES</h3>
                </div>
                <p className="description">13816 Bora Bora Way Marina Del rey Los Angeles, CA</p>
                <a href="https://www.google.com/maps/place/Mobylogix,+Inc./@33.9709997,-118.4527544,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2b16de30d4acb:0xd05cdd16ca45e065!8m2!3d33.9709997!4d-118.4505604" target={'_blank'} rel="nooppener noreferrer" className="link-underline">Direction</a>
              </div>
            </Col> */}
            <Col xs="12" md="6" lg="3">
              <div className="address-box">
                <div className="title-wrap">
                  <img src="images/pk.png" alt="" className="flag-icon" />
                  <h3 className="title">PAKISTAN</h3>
                </div> 
                <p className="description">251, Block G1 Block G 1 Phase 1 Johar Town, Lahore, Punjab 54782</p>
                <a href="https://www.google.com/maps/place/Mobylogix/@31.4772214,74.2784302,17z/data=!3m1!4b1!4m5!3m4!1s0x391903c4b8091bd7:0xadfacb97cca2d128!8m2!3d31.4772214!4d74.2806242" target={'_blank'} rel="nooppener noreferrer" className="link-underline">Direction</a>
              </div>
            </Col>
            <Col xs="12" md="6" lg="3">
              <div className="services-wrap">
                <h3 className="title">Services</h3>
                <ul className="services-list">
                  <li><a href="/mobile-app-development" rel="nooppener">Mobile App Development</a></li>
                  <li><a href="/blockchain-development" rel="nooppener">Blockchain Development</a></li>
                  <li><a href="/ecommerce-development" rel="nooppener">Ecommerce Development</a></li>
                  <li><a href="/machine-learing" rel="nooppener">AI Development</a></li>
                </ul>
              </div>
            </Col>
            <Col xs="12" md="6" lg="3">
              <div className="services-wrap">
                <h3 className="title">&nbsp;</h3>
                <ul className="services-list">
                  <li><a href="/custom-enterprise-software-development" rel="nooppener">Custom Software Development</a></li>
                  <li><a href="/mvp-development" rel="nooppener">MVP Development</a></li>
                  <li><a href="/virtual-reality" rel="nooppener">Virtual Reality Development</a></li>
                  <li><a href="/mvp-development" rel="nooppener">Saas Web Development</a></li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <Container>
          <Row>
            <Col xs="12" md="6">
              <p>© 2025 Mobylogix. All Rights Reserved. <a href="/privacy-policy">Privacy Policy</a>. </p>
            </Col>
            <Col xs="12" md="6">
              {/* <ul className="social-links">
                <li><a href="https://www.linkedin.com/company/mobylogix" target="_blank" rel="nooppener noreferrer"><LinkedinIcon /></a></li>
                <li><a href="https://www.facebook.com/mobylogix" target="_blank" rel="nooppener noreferrer"><FacebookIcon /></a></li>
                <li><a href="https://www.instagram.com/mobylogixinc" target="_blank" rel="nooppener noreferrer"><InstagramIcon /></a></li>
                <li><a href="https://twitter.com/mobylogix" target="_blank" rel="nooppener noreferrer"><TwitterIcon /> </a></li>
              </ul> */}
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  )
}

Footer.propTypes = {};

export default Footer;

const TwitterIcon = () => (
  <svg className="social-icon" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" version="1.1" style={{shapeRendering: "geometricPrecision", textRendering: "geometricPrecision", imageRendering: "optimizeQuality", fillRule: "evenodd", clipRule: "evenodd"}}
    viewBox="0 0 23.73 19.19">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <path d="M23.62 2.58c0.16,0.13 0.12,0.06 0.01,0.2 -0.85,1.11 -0.86,1.1 -2.22,1.82 0,3.25 -0.38,5.52 -2.05,8.32 -0.67,1.12 -0.7,1.06 -1.48,1.98 -0.59,0.7 -1.04,1.15 -1.77,1.69 -0.75,0.56 -1.23,0.88 -2.13,1.33 -4,2 -9.08,1.41 -13.1,-0.19 -0.18,-0.07 -0.3,-0.11 -0.48,-0.19l-0.41 -0.26c1.53,0 2.76,-0.12 3.93,-0.49 0.82,-0.26 2.46,-0.94 2.89,-1.53 -1.98,-0.46 -3.91,-1.28 -4.42,-3.46 1.02,0.24 1.28,0 2.11,0l0 -0.19c-1.06,-0.25 -2.77,-1.55 -3.4,-2.85 -0.3,-0.64 -0.35,-0.86 -0.35,-1.76 0.59,0.14 0.95,0.48 2.02,0.48 0.54,0 0.12,-0.24 -0.06,-0.4 -0.23,-0.21 -0.33,-0.4 -0.54,-0.63 -0.38,-0.42 -0.63,-0.87 -0.87,-1.43 -0.38,-0.9 -0.4,-1.96 -0.14,-2.89 0.08,-0.28 0.2,-0.56 0.27,-0.88 0.33,0.09 0.77,0.54 1.03,0.79 1.25,1.22 3.93,2.9 5.69,3.43 1.21,0.36 1.2,0.33 2.35,0.44l1.11 -0.06c0,-2.42 0.38,-4.47 2.81,-5.45 1.5,-0.6 3.32,-0.56 4.64,0.4 0.24,0.17 0.43,0.35 0.64,0.51 0.78,0.57 2.72,-0.42 3.33,-0.74 -0.01,0.63 -0.41,0.99 -0.72,1.39 -0.25,0.32 -0.26,0.27 -0.55,0.51 -0.45,0.38 -0.48,0.3 -0.55,0.6 0.72,-0.02 2.12,-0.3 2.4,-0.48z"/>
    </g>
  </svg>
);

const FacebookIcon = () => (
  <svg className="social-icon" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" version="1.1" style={{shapeRendering: "geometricPrecision", textRendering: "geometricPrecision", imageRendering: "optimizeQuality", fillRule: "evenodd", clipRule: "evenodd"}}
    viewBox="0 0 4.02 8.62">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <path d="M2.65 1.8l0 0.99 1.37 0c-0.03,0.11 -0.04,0.28 -0.05,0.39l-0.08 0.79c-0.06,0.42 0.24,0.33 -1.24,0.33l0 4.31 -1.78 0 0 -4.31 -0.87 0 0 -1.51c1.04,0 0.87,0.12 0.87,-0.47 -0,-0.55 -0.04,-1.01 0.2,-1.49 0.12,-0.24 0.3,-0.43 0.52,-0.58 0.19,-0.13 0.56,-0.26 0.87,-0.26l1.51 0 0 1.46 -0.93 0c-0.22,0 -0.41,0.14 -0.41,0.35z"/>
    </g>
  </svg>
);

const LinkedinIcon = () => (
  <svg className="social-icon" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" version="1.1" style={{shapeRendering: "geometricPrecision", textRendering: "geometricPrecision", imageRendering: "optimizeQuality", fillRule: "evenodd", clipRule: "evenodd"}}
    viewBox="0 0 19 18.93">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <g id="_2344214321440">
      <path d="M2.33 4.52c-1.31,0 -2.33,-1.05 -2.33,-2.26 0,-1.16 0.95,-2.26 2.12,-2.26 0.74,0 1.37,0.12 1.89,0.72 1.29,1.5 0.21,3.8 -1.68,3.8z"/>
      <path d="M10.66 12.29l0 6.64 -3.88 0 0 -12.64 3.74 0 0 1.77c0.15,-0.11 1.18,-2.05 3.46,-2.05 1.6,0 3,0.11 4,1.44 0.63,0.84 1.02,2.21 1.02,3.64l0 7.84 -3.88 0 0 -6.92c0,-3.32 -2.93,-2.96 -3.87,-1.82 -0.35,0.43 -0.58,1.34 -0.58,2.1z"/>
      <polygon points="0.35,6.28 4.24,6.28 4.24,18.93 0.35,18.93 "/>
      </g>
    </g>
  </svg>
);

const InstagramIcon = () => (
  <svg className="social-icon" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" version="1.1" style={{shapeRendering: "geometricPrecision", textRendering: "geometricPrecision", imageRendering: "optimizeQuality", fillRule: "evenodd", clipRule: "evenodd"}}
    viewBox="0 0 13.7 13.72">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <path d="M2.61 7.04l0 -0.34c0,-2.13 1.94,-4.07 4.07,-4.07 1.37,0 2.38,0.42 3.18,1.23 2.66,2.67 0.7,7.25 -2.84,7.25l-0.34 0c-2.13,0 -4.07,-1.94 -4.07,-4.07zm7.69 -4.58c0,-1.36 1.98,-1.28 1.98,-0.06 0,1.36 -1.98,1.28 -1.98,0.06zm-10.29 3.11c0,1.48 -0.04,3.84 0.11,5.21 0.13,1.15 0.66,2.04 1.63,2.5 0.27,0.13 0.74,0.27 1.05,0.31 1.45,0.18 6.59,0.17 8.08,0.01 0.79,-0.09 1.43,-0.43 1.89,-0.89 0.47,-0.47 0.72,-1.08 0.83,-1.88 0.18,-1.29 0.16,-6.52 0.01,-7.86 -0.13,-1.15 -0.66,-2.04 -1.63,-2.5 -1.29,-0.62 -4.97,-0.43 -6.52,-0.43 -2.23,0 -5,-0.11 -5.32,2.88 -0.09,0.82 -0.11,1.76 -0.11,2.66z"/>
      <path d="M4.08 6.63c0,0.92 0.12,1.51 0.8,2.19 1.08,1.08 2.84,1.09 3.93,0 1.82,-1.82 0.31,-4.74 -1.74,-4.74 -0.92,0 -1.51,0.12 -2.19,0.8 -0.37,0.37 -0.8,1.04 -0.8,1.74z"/>
    </g>
  </svg>
);
